/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // Nav toggle
        $('.js-nav-toggle').on('click', function() {
          $(this).toggleClass('open');
          $('.js-nav-pri, .js-nav-sec').slideToggle();
          return false;
        });

        //filter toggle
        $('.js-nav-filters-toggle').on('click', function() {
          $('.js-filters').slideToggle();
          return false;
        });

        // AJAX for portfolio section
        var ajaxLoadMorePortfolio = function($addMorePosts, $currentTaxonomySlug) { 

          if($currentTaxonomySlug === undefined) {
            $currentTaxonomySlug = '';
          }

          $.ajax({
            type: 'GET',
            url: ajaxurl,
            data: {
              'action':'load_more_portfolio',
              'add_more_posts':$addMorePosts,
              'current_taxonomy_slug':$currentTaxonomySlug
            },
            success: function(data) {
              // console.log('success');
              // console.log(data);
              $('#portfolio').html(data);

              $('.js-portfolio').removeClass('loading');
              $('.js-spinner').hide();

              $('.js-grid').isotope({
                itemSelector: '.grid-item',
                percentPosition: true,
                masonry: {
                  columnWidth: '.grid-sizer',
                  gutter: 15,
                  horizontalOrder: true
                }
              });

              $('.js-grid').isotope('reloadItems');
            },
            error: function(errorThrown){
              console.log(errorThrown);
            }
          });
        };

        var $addMorePosts = 1;


        $('.js-nav-filter-link').on('click', function() {
          // Reset counter on nav filter click
          $addMorePosts = 1;
          var $currentTaxonomySlug = $(this).data('slug');

          // Remove active class for parent
          $('.js-nav-filter-link').parent().removeClass('active js-active');

          // Hide children then show current children
          if (!$(this).hasClass('js-nav-filter-link-child')) {
            $('.js-sub-filters').hide();
          } else {
            // Remove active class for other children
            $('.js-nav-filter-link-child').parent().removeClass('active js-active');
          }

          // Active class for nav-filter
          $(this).parent().addClass('active js-active');

          // Active class for parent
          if ($(this).hasClass('js-nav-filter-link-child')) {
            $(this).parent().parent().parent().addClass('active js-active');
          }


          $(this).next('.js-sub-filters').fadeToggle();
          // $(this).parentsUntil('.js-parent').find('.js-nav-filter-link').addClass('active js-active');

          // Spinner for loading
          $('.js-portfolio').addClass('loading');
          $('.js-spinner').show();

          ajaxLoadMorePortfolio($addMorePosts, $currentTaxonomySlug);
          return false;
        });

        // Isotope
        if ($('.js-grid').length) {
          $('.js-grid').isotope({
            itemSelector: '.grid-item',
            percentPosition: true,
            masonry: {
              columnWidth: '.grid-sizer',
              gutter: 15,
              horizontalOrder: true
            }
          });
        }
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        $('.carousel').carousel({
          interval: 4000
        });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    'single_portfolio': {
      init: function() {

        // Flexslider
        // http://flexslider.woothemes.com/thumbnail-slider.html
        // The slider being synced must be initialized first
        $('#carousel').flexslider({
          animation: "slide",
          controlNav: false,
          animationLoop: false,
          slideshow: false,
          itemWidth: 210,
          itemMargin: 5,
          asNavFor: '#slider'
        });
       
        $('#slider').flexslider({
          animation: "slide",
          controlNav: false,
          animationLoop: false,
          slideshow: false,
          sync: "#carousel"
        });

        $('.flex-prev').html('');
        $('.flex-next').html('');
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
